import { useEffect, useState } from "react";
import { UserProfile, getProUsers } from "~/api/userApi";
import { keyframes, styled } from "~/ui/style/stitches.config";
import { StyledProfilePic } from "~/ui/components/ProfilePic";
import { Button } from "~/ui/components/Button";
import { SimpleLoadingIndicator } from "../loading/LoadingPage";
import { getIsUserBookable } from "~/api/bookableChecks";
import { getAvailabilityByUuid } from "~/api/calendarApi";
import { emojiStrippedText } from "~/utils/strings";
import { ProFilterOptions, ProFilters } from "./ProFilters";
import { AvailabilityRequest } from "~/utils/datetime";
import { MobileOnlyBr } from "./DeckMarketplace";
import { imageUrl, mp4Url } from "~/utils/imageurl";
import { set } from "date-fns";
import { useLocation } from "wouter";
import { zIndex } from "html2canvas/dist/types/css/property-descriptors/z-index";
import { setVideoModalVisible } from "./booking/BookingModule";
import { trackEvent } from "~/api/analyticsApi";

const LearnMoreData = [
  {
    title: "Browse the talent",
    subtitle: "Explore curated profiles and see who resonates.",
    icon: "/images/room/search.svg",
  },
  {
    title: "Request a session",
    subtitle: "Pick a convenient time & send the host a note.",
    icon: "/images/feedback.svg",
  },
  {
    title: "Meet in a tarot room",
    subtitle: "Enjoy an illuminating experience from your browser.",
    icon: "/images/moon.svg",
  },
];
const LearnMoreOverlay = ({
  setIsViewingLearnMore,
}: {
  setIsViewingLearnMore: Function;
}) => {
  return (
    <StyledLearnMoreOverlay onClick={() => setIsViewingLearnMore(false)}>
      <div
        className="learn-more-container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="x-bar">
          <img
            className="btn x"
            src="/images/exit.svg"
            alt="x"
            onClick={() => {
              setIsViewingLearnMore(false);
            }}
          />
        </div>
        <div className="learn-more-text">
          <div className="learn-more-section first">
            <div className="section-title">What is a Moonlight session?</div>
            <div className="section-body">
              Moonlight is a new platform for live social tarot. We built
              beautiful software for running sessions in browser with vetted
              readers and teachers. To get a feel for the experience,{" "}
              <span
                className="text-button"
                onClick={() => {
                  // set back to homepage
                  window.open("/", "_blank");
                }}
              >
                explore our sandbox
              </span>{" "}
              and start a tarot room.
            </div>
          </div>
          <div className="learn-more-section">
            <div className="section-title">
              How booking a session works
              <div className="info-section">
                {LearnMoreData.map((data) => (
                  <div className="inner-info-section" key={data.title}>
                    <img className="icon" src={data.icon} />
                    <div className="text-container">
                      <div className="title">{data.title}</div>
                      <div className="subtitle">{data.subtitle}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="section-body">
              New to tarot? Read about{" "}
              <span
                className="text-button"
                onClick={() =>
                  window.open(
                    "https://blog.moonlight.world/meet-our-tarot-pros/",
                    "_blank"
                  )
                }
              >
                what to expect
              </span>{" "}
              during a session.
            </div>
          </div>
          <div className="learn-more-section">
            <div className="section-title">Secure payments</div>
            <div className="section-body">
              You’re only charged after the session is confirmed. And we have a{" "}
              <span
                className="text-button"
                onClick={() =>
                  window.open(
                    "https://help.moonlight.world/article/51-our-refund-policy",
                    "_blank"
                  )
                }
              >
                flexible refund policy
              </span>{" "}
              for no-shows.
            </div>
          </div>
          <div className="learn-more-section">
            <div className="section-title">Community guidelines</div>
            <div className="section-body">
              We’re committed to creating a safe and respectful environment.
              Everyone participating in sessions must agree to{" "}
              <span
                className="text-button"
                onClick={() =>
                  window.open(
                    "https://help.moonlight.world/article/52-community-guidelines",
                    "_blank"
                  )
                }
              >
                our guidelines.
              </span>
            </div>
          </div>
        </div>
      </div>
    </StyledLearnMoreOverlay>
  );
};

const StyledLearnMoreOverlay = styled("div", {
  position: "fixed",
  top: "0px",
  left: "0px",
  width: "100vw",
  height: "100%",
  zIndex: "100000",
  display: "flex",
  justifyContent: "center",
  backgroundColor: "rgba(0,0,0,0.5)",
  "& .learn-more-container": {
    position: "relative",
    "&::-webkit-scrollbar": {
      webkitAppearance: "none",
      width: "8px",
      marginLeft: "1px",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "20px",
      backgroundColor: "rgba(0, 0, 0, .5)",
      border: "1px solid $wash",
    },
    "&::-webkit-scrollbar-track": {
      overflow: "hidden",
      marginTop: "60px",
      marginBottom: "30px",
      width: "8px",
    },
    scrollMarginTop: "10px",
    marginTop: "100px",
    backgroundColor: "$wash",
    borderRadius: "33px",
    border: "1px solid black",
    padding: "0 38px 16px 38px",

    marginLeft: "$navWidth",
    "@mobile": {
      marginLeft: "0px",
    },

    width: "500px",
    height: "calc(100% - 200px)",
    overflowY: "scroll",
    overflowX: "hidden",
    "& .x-bar": {
      position: "sticky",
      backgroundColor: "$wash",
      top: "0px",
      width: "calc(100% + 16px)",
      display: "flex",
      justifyContent: "flex-end",
    },
    " & .btn.x": {
      paddingBottom: "12px",
      paddingTop: "18px",
      cursor: "pointer",
      transition: "opacity 0.2s ease-out",
      "&:hover": {
        opacity: "0.5",
      },
    },

    "& .learn-more-section": {
      "&.first": {
        marginTop: "22px",
      },
      marginBottom: "32px",
      "& .section-title": {
        fontSize: "24px",
        fontFamily: "KeplerLightCondensed",
      },
      "& .info-section": {
        "& .inner-info-section": {
          display: "flex",
          marginTop: "36px",
          marginBottom: "36px",
        },
        backgroundColor: "white",
        padding: "8px 28px",
        border: "0.5px solid rgba(0, 0, 0, 0.5)",
        borderRadius: "15px",
        marginBottom: "12px",
        marginTop: "8px",
        "& .icon": {
          marginRight: "18px",
        },
        "& .text-container": {
          "& .title": {
            fontFamily: "WorkSans",
            fontSize: "13px",
            fontWeight: "500",
          },
          "& .subtitle": {
            fontFamily: "WorkSans",
            fontSize: "12px",
            marginTop: "4px",
          },
        },
      },
      "& .section-body": {
        fontSize: "12px",
        marginTop: "6px",
        lineHeight: "1.3",
        "& .text-button": {
          cursor: "pointer",
          textDecoration: "underline",
          transition: "opacity 0.2s ease-out",
          "&:hover": {
            opacity: "0.5",
          },
        },
      },
    },
  },
  "@mobile": {
    "& .learn-more-container": {
      width: "100vw",
      height: "100%",
      marginTop: "0",
      borderRadius: 0,
      border: "none",
    },
  },
});

const OpenForBookingBadge = ({
  lowestPrice,
  areMorePrices,
  style,
  isHovered,
}: {
  lowestPrice: number;
  areMorePrices: boolean;
  style?: Object;
  isHovered: boolean;
}) => {
  return (
    <StyledOpenForBookingBadge style={style}>
      <div className="booking-text">
        {isHovered ? (
          <span className="hover-text">BOOKINGS OPEN</span>
        ) : (
          <>
            {`$${lowestPrice}${areMorePrices ? "+" : ""}`}
            <span className="session-text">{` / session`}</span>
          </>
        )}
      </div>
    </StyledOpenForBookingBadge>
  );
};

const ProCard = ({
  pro,
  setIsCursorShowing,
  setCurrVideoProfile,
}: {
  pro: AvailableUserProfile;
  setIsCursorShowing: Function;
  setCurrVideoProfile: Function;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <StyledProCard
      key={pro.username + "-pro-card"}
      onPointerEnter={() => {
        setIsHovered(true);
        setIsCursorShowing(true);
      }}
      onPointerLeave={() => {
        setIsHovered(false);
        setIsCursorShowing(false);
      }}
      onClick={() => {
        if (pro.proDetails?.hasVideo && pro.isAvailable) {
          setCurrVideoProfile(pro);
        } else {
          window.open("/profile/" + pro.username, "_blank");
        }
        trackEvent("/book/selected_pro", {
          username: pro.username,
        });
      }}
    >
      {pro.isAvailable && (
        <OpenForBookingBadge
          lowestPrice={pro.lowestPrice}
          areMorePrices={pro.areMorePrices}
          isHovered={isHovered}
          style={{
            position: "absolute",
            top: "12px",
            right: "7px",
          }}
        />
      )}
      <div
        className="profile-pic-wrapper"
        style={{
          width: "80%",
          // backgroundColor: "blue",
          marginLeft: "2%",
          position: "relative",
        }}
      >
        {pro.proDetails?.hasVideo && pro.isAvailable && (
          <StyledVideoPlayButton
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              margin: "auto",
              width: "32px",
            }}
            className="play-button"
            src="/images/play-button.png"
          />
        )}
        <StyledProfilePic
          css={{
            $$size: "100%",
            $$padding: "12px",
            flexShrink: 0,
          }}
          src={imageUrl("/profile-pics/" + pro.profilePic, 300)}
        ></StyledProfilePic>
      </div>

      <div className="card-content">
        <h1 className="name">{emojiStrippedText(pro.name)}</h1>
        <h2 className="tagline">{pro.tagline}</h2>
        <div className="bio">{pro.bio}</div>
      </div>
      <div className="tags">
        {["author", "artist", "teacher", "reader"].map((tag, i) =>
          pro.tags?.includes(tag) ? (
            <div key={"tag" + i} className={`tag ${tag}`}>
              {tag}
            </div>
          ) : (
            <div style={{ display: "none" }} key={"tag" + i}></div>
          )
        )}
      </div>
    </StyledProCard>
  );
};

// keyframes rotate
const fadeIn = keyframes({
  "0%": {
    opacity: "0",
  },

  "100%": {
    opacity: "1",
  },
});

export const StyledVideoPlayButton = styled("img", {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  margin: "auto",
  width: "32px",
  height: "32px",
  transition: "0.2s all",
  "&:hover": {
    opacity: 0.7,
  },
});

const StyledOpenForBookingBadge = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "0px",
  marginRight: "10px",
  "& .booking-text, & .hover-text": {
    opacity: 1,
    animation: `${fadeIn} 0.4s ease-in`,
  },
  "& .hover-text": {
    // fontWeight: "300",
    fontSize: "12px",
    fontWeight: "400",
  },
  "& .booking-text": {
    fontSize: "13px",
    border: "1px solid black",
    borderRadius: "35px",
    width: "120px",
    height: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "lowercase",
    boxShadow: "0px 2px 0px 0px #000",
    "& .session-text": {
      fontWeight: "300",
      textTransform: "lowercase",
      fontSize: "12px",
      marginLeft: "4px",
    },
  },
});

export type AvailableUserProfile = UserProfile & {
  isAvailable: boolean;
  lowestPrice: number;
  areMorePrices: boolean;
  availability: AvailabilityRequest;
  hasVideo: boolean;
};

const SenjaWidget = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src =
      "https://widget.senja.io/widget/bdac1271-423c-4e13-a4c3-da838b1df8e0/platform.js";
    script.type = "text/javascript";
    script.async = true;

    // Append the script to the body or a specific element
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <StyledSenjaWidget
      style={{
        width: "calc(90% - 24px)",
        marginTop: "10px",
        maskImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%)",
        WebkitMaskImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0) 100%)",
        /* For Safari */ padding: "42px 0",
      }}
    >
      <div
        style={{
          fontSize: "27px",
          textAlign: "center",
          fontFamily: "KeplerLightCondensed",
          marginBottom: "14px",
        }}
      >
        What people are saying
      </div>
      <div
        className="senja-embed"
        data-id="bdac1271-423c-4e13-a4c3-da838b1df8e0"
        data-mode="shadow"
        data-lazyload="false"
        style={{
          display: "block",
          width: "100%",
        }}
      ></div>
      <div
        style={{
          textAlign: "center",
          fontSize: "13px",
          marginTop: "12px",
          fontWeight: "400",
        }}
      >
        <a href="https://reviews.moonlight.world" target="_blank">
          Read more reviews
        </a>
      </div>
    </StyledSenjaWidget>
  );
};

const StyledSenjaWidget = styled("div", {
  "@mobile": {
    width: "100% !important",
    maskImage: "none !important",
    WebkitMaskImage: "none !important",
  },
});

const VideoOverlay = ({
  videoProfile,
  setCurrVideoProfile,
  advanceVideoProfile,
  goBackVideoProfile,
  priceLabel,
}: {
  videoProfile: AvailableUserProfile;
  setCurrVideoProfile: Function;
  advanceVideoProfile: Function;
  goBackVideoProfile: Function;
  priceLabel: string;
}) => {
  const [_, setLocation] = useLocation();

  return (
    <StyledVideoOverlay
      onClick={(e) => {
        e.stopPropagation();
        setCurrVideoProfile(null);
      }}
    >
      <div className="pro-container" onClick={(e) => e.stopPropagation()}>
        <img
          className="x-button"
          onClick={() => setCurrVideoProfile(null)}
          src="/images/exit.svg"
        />
        <img
          onClick={(e) => {
            e.stopPropagation();
            advanceVideoProfile();
          }}
          className="arrow next-arrow"
          src="/images/next-arrow-booking.svg"
        ></img>
        <img
          onClick={(e) => {
            e.stopPropagation();
            goBackVideoProfile();
          }}
          className="arrow prev-arrow"
          src="/images/next-arrow-booking.svg"
        ></img>

        <div className="video-container">
          <video
            autoPlay
            playsInline
            loop
            src={"/images/pro-videos/" + videoProfile.username + ".mp4"}
          />
          <div className="video-loading" />
        </div>
        <div className="text-box">
          <div className="name">{videoProfile.name}</div>
          <div className="tagline">Tarot Professional</div>
          <div className="price">
            <span className="amount">{`$${priceLabel}`}</span>
            <span className="session">{`/ session`}</span>
          </div>
          <Button
            bookingButton
            style={{
              backgroundColor: "#F2C889",
              marginTop: "14px",
              padding: "14px 32px",
              borderRadius: "14px",
            }}
            onClick={() => {
              setLocation("/profile/" + videoProfile.username);
            }}
          >
            See profile
          </Button>
        </div>
      </div>
    </StyledVideoOverlay>
  );
};

const pulseOpacity = keyframes({
  "0%": {
    opacity: 0.4,
  },
  "50%": {
    opacity: 1,
  },
  "100%": {
    opacity: 0.4,
  },
});

export const StyledVideoOverlay = styled("div", {
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  backgroundColor: "rgba(217, 217, 217, 0.7)",
  zIndex: 100000,
  display: "flex",
  width: "100%",
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
  "& .pro-container": {
    flexDirection: "column",
    "& .x-button": {
      position: "absolute",
      top: "24px",
      right: "24px",
      cursor: "pointer",
      width: "32px",
      height: "32px",
      "&:hover": {
        opacity: 0.7,
      },
    },

    width: "500px",
    height: "520px",
    backgroundColor: "$wash",
    borderRadius: "31px",
    border: "1px solid rgba(0, 0, 0, 0.3)",
    boxShadow: "rgba(0, 0, 0, 1) 0px 3px 0px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "& .arrow": {
      position: "absolute",
      top: "50%",
      marginTop: "-16px",
      cursor: "pointer",
      width: "65px",
      "&:hover": {
        opacity: 0.7,
      },
      "&.next-arrow": {
        right: "10px",
      },
      "&.prev-arrow": {
        left: "10px",
        transform: "rotate(180deg)",
      },
    },
  },
  "& button": {
    "@mobile": {
      fontSize: "14px",
    },
  },
  "& .video-container": {
    width: "240px",
    height: "240px",
    borderRadius: "50%",
    overflow: "hidden",
    border: "1px solid black",
    backgroundColor: "$gray200",
    position: "relative",
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      filter: "grayscale(1)",
      pointerEvents: "none",
      borderRadius: "50%",
      zIndex: 1,
      position: "absolute",
    },
    "& .video-loading": {
      width: "100%",
      height: "100%",
      position: "absolute",
      backgroundColor: "$gray400",
      zIndex: 0,
      top: 0,
      left: 0,
      animation: `${pulseOpacity} 2s infinite`,
      borderRadius: "50%",
    },
    "& video::-webkit-media-controls": {
      display: "none",
    },
  },
  "& .text-box": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    "& .name": {
      fontSize: "30px",
      fontFamily: "GorgeousBaby",
    },
    "& .tagline": {
      fontSize: "13px",
      marginTop: "5px",
    },
    "& .sub-tagline": {
      fontSize: "13px",
      width: "250px",
      textAlign: "center",
      marginTop: "6px",
      marginBottom: "10px",
    },
    "& .price": {
      fontSize: "12px",
      display: "flex",
      alignItems: "center",
      marginTop: "5px",
      "& .amount": {
        fontWeight: "500",
      },
      "& .session": {
        marginLeft: "5px",
        textTransform: "uppercase",
        fontWeight: "300",
        fontSize: "10px",
      },
    },
  },
  "@mobile": {
    "& .pro-container": {
      width: "100%",
      height: "100%",
      borderRadius: "0",
      border: "none",
    },
  },
});

export const ProDirectory = () => {
  const [pros, setPros] = useState<AvailableUserProfile[]>([]);
  const [cursorPos, setCursorPos] = useState({ x: -1000, y: -1000 });
  const [isCursorShowing, setIsCursorShowing] = useState(false);
  const [activeBulletIdx, setActiveBulletIdx] = useState(0);
  const [bulletTimeout, setBulletTimeout] = useState(0);
  const [activeProFilters, setActiveProFilters] = useState<ProFilterOptions[]>(
    []
  );

  const [currVideoProfile, setCurrVideoProfile] =
    useState<AvailableUserProfile | null>(null);

  const [isViewingLearnMore, setIsViewingLearnMore] = useState(false);

  const availablePros = pros && pros.filter((p) => p.isAvailable);
  const unavailablePros = pros && pros.filter((p) => !p.isAvailable);

  const [filteredAvailablePros, setFilteredAvailablePros] =
    useState<AvailableUserProfile[]>(availablePros);

  const areAvailablePros = availablePros && filteredAvailablePros.length > 0;

  const allVideoPros = availablePros.filter((p) => p.hasVideo);

  useEffect(() => {
    const filteredAvailablePros = availablePros.filter((pro) => {
      let isProFiltered = false;
      activeProFilters.forEach((filter) => {
        if (!filter.filter(pro)) isProFiltered = true;
      });
      return !isProFiltered;
    });
    setFilteredAvailablePros(filteredAvailablePros);
  }, [availablePros.length, activeProFilters]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currVideoProfile) {
      trackEvent("/book/watched_video", {
        username: currVideoProfile.username,
      });
    }
  }, [currVideoProfile]);

  useEffect(() => {
    (async () => {
      const proInfo = (await getProUsers()) as UserProfile[];

      const availableProInfo = await Promise.all(
        proInfo.map(async (p) => {
          // availability check logic
          const isAvailable = await getIsUserBookable(p);
          const availabilityData = await getAvailabilityByUuid(p.uuid);
          const offeredServices = availabilityData?.services
            ? availabilityData.services.filter((s) => s.offered)
            : [];
          const lowestPrice = Math.min(...offeredServices.map((s) => s.price));
          const areMorePrices = offeredServices.length > 1;
          const hasVideo = p.proDetails?.hasVideo ? true : false;
          return {
            ...p,
            isAvailable: isAvailable ? true : false,
            lowestPrice,
            areMorePrices,
            availability: availabilityData,
            hasVideo: hasVideo,
          };
        })
      );
      setPros([...availableProInfo]);
      setIsLoading(false);
    })();
    trackEvent("/book/visited_page");
  }, []);

  useEffect(() => {
    window.clearTimeout(bulletTimeout);
    setBulletTimeout(
      window.setTimeout(() => {
        setActiveBulletIdx((activeBulletIdx + 1) % 3);
      }, 6000)
    );
  }, [activeBulletIdx]);

  return (
    <div
      onMouseEnter={(e) => {
        setCursorPos({ x: e.clientX, y: e.clientY });
      }}
      onMouseMove={(e) => {
        setCursorPos({ x: e.clientX, y: e.clientY });
      }}
      style={{ width: "100%", height: "100%", overflow: "scroll" }}
    >
      {isViewingLearnMore && (
        <LearnMoreOverlay setIsViewingLearnMore={setIsViewingLearnMore} />
      )}
      {currVideoProfile && (
        <VideoOverlay
          videoProfile={currVideoProfile}
          advanceVideoProfile={() => {
            const currIdx = allVideoPros.findIndex(
              (p) => p.username === currVideoProfile.username
            );

            const nextIdx = (currIdx + 1) % allVideoPros.length;
            setCurrVideoProfile(allVideoPros[nextIdx]);
          }}
          goBackVideoProfile={() => {
            const currIdx = allVideoPros.findIndex(
              (p) => p.username === currVideoProfile.username
            );
            const nextIdx =
              currIdx === 0 ? allVideoPros.length - 1 : currIdx - 1;
            setCurrVideoProfile(allVideoPros[nextIdx]);
          }}
          setCurrVideoProfile={setCurrVideoProfile}
          priceLabel={`${currVideoProfile.lowestPrice}${
            currVideoProfile.areMorePrices ? "+" : ""
          }`}
        />
      )}
      <div
        className="view-cursor"
        style={{
          position: "fixed",
          display: isCursorShowing ? "flex" : "none",
          backgroundColor: "black",
          borderRadius: "50%",
          zIndex: "999",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "13px",
          pointerEvents: "none",
          width: "50px",
          height: "50px",
          color: "white",
          top: "-25px",
          left: "-25px",
          border: "1px solid white",
          transform: `translate(${cursorPos.x}px, ${cursorPos.y}px)`,
        }}
      >
        view
      </div>
      <StyledProDirectory className="bookings">
        <div className="text-container orange-header">
          <div className="text">
            <h1 className="title">Get a tarot reading or lesson</h1>
            <h3 className="sub-subtitle">
              A judgement-free zone for exploring your thoughts, dreams, and
              crossroads with vetted professionals.
              <div style={{ height: "20px" }} />
              <span style={{ fontSize: "13px" }}>
                Starts at $25 for a 15-minute reading
              </span>
            </h3>
            <div className="buttons-container">
              <Button
                Theme={"dark"}
                style={{
                  width: "144px",
                  height: "50px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 0,
                  border: "none",
                }}
                onClick={() => {
                  // scroll to pro filters - 20px
                  document.getElementById("pro-filters")?.scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                Find a reader
              </Button>
            </div>
          </div>

          <div
            className="bookings-header-img-container"
            style={{
              position: "relative",
            }}
          >
            <div
              className="scrolling-overlay"
              style={{
                position: "absolute",
                height: "90%",
                width: "100%",
                top: 0,
                left: 0,
                zIndex: 99,
              }}
            ></div>
            <video
              className="bookings-header-img"
              autoPlay
              playsInline
              muted
              loop
              onScroll={(e) => {
                e.preventDefault();
              }}
              src={mp4Url("/booking_demo_video.mp4", "medium")}
            ></video>
          </div>
        </div>
        <SenjaWidget />

        <ProFilters setActiveProFilters={setActiveProFilters} />

        {isLoading ? (
          <div
            style={{
              position: "relative",
              width: "100%",
              marginTop: "40px",
              opacity: "0.5",
            }}
          >
            <SimpleLoadingIndicator />
          </div>
        ) : (
          <>
            {areAvailablePros ? (
              <>
                <div className="cards available-cards">
                  {availablePros.map((pro, i) => {
                    let isProFiltered = false;
                    activeProFilters.forEach((filter) => {
                      if (!filter.filter(pro)) isProFiltered = true;
                    });
                    return isProFiltered ? (
                      <></>
                    ) : (
                      <ProCard
                        key={pro.username + "available-card"}
                        pro={pro}
                        setIsCursorShowing={setIsCursorShowing}
                        setCurrVideoProfile={setCurrVideoProfile}
                      />
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="no-available">
                <div className="l2">
                  Hmm, no available matches. <MobileOnlyBr />
                  Try adjusting your search filters.
                </div>
              </div>
            )}
            <div className="text-container more-pros-header">
              <div className="text">
                <div className="title">Explore more pros</div>
                <div className="subtitle">
                  Learn more about tarot through the artists, authors, readers,
                  and teachers in Moonlight’s evolving professional community.
                </div>
              </div>
            </div>
            <div className="cards unavailable-cards">
              {unavailablePros.map((pro, i) => {
                return (
                  <ProCard
                    key={pro.username + "unavaliable-card"}
                    pro={pro}
                    setIsCursorShowing={setIsCursorShowing}
                    setCurrVideoProfile={setCurrVideoProfile}
                  />
                );
              })}
            </div>
          </>
        )}
      </StyledProDirectory>
    </div>
  );
};

const fadein = keyframes({
  "0%": {
    opacity: 0,
    backgroundColor: "$wash",
  },

  "100%": {
    opacity: 1,
    backgroundColor: "$wash",
  },
});

export const StyledProDirectory = styled("div", {
  backgroundColor: "$wash",
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  paddingBottom: "120px",

  "@notmobile": {
    marginLeft: "calc($navWidth - 20px)",
  },

  animation: `${fadein} 0.4s`,

  flexDirection: "column",
  paddingTop: "160px",
  "&:not(.bookings) @bp3": {
    paddingTop: "100px",
    paddingBottom: "150px",
    "& .text-container": { flexDirection: "column" },
  },
  "@mobile": {
    paddingTop: "100px",
    paddingBottom: "150px",
    "& .text-container": { flexDirection: "column" },
  },

  "& .text-container": {
    display: "flex",

    maxWidth: "90%",
    textAlign: "center",
  },
  "& .dark-header.text-container": {
    backgroundColor: "black",
    // marginLeft: "12px",
    "& .title, & .subtitle, & .sub-subtitle": {
      color: "white",
    },
  },

  "& .dark-header.text-container, & .orange-header.text-container": {
    width: "calc(90% - 24px)",
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "33px",
    padding: "50px 40px",
    marginTop: "-98px",

    "& .buttons-container": {
      marginTop: "12px",
    },

    "@mobile": {
      marginTop: "-40px !important",
    },

    "@bp900": {
      width: "calc(90%)",
      flexDirection: "column",
      paddingTop: "60px",
      paddingBottom: "40px",
    },

    "& .text": {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
      marginBottom: 0,
      marginLeft: "60px",
      // width: "430px",
      maxWidth: "400px",
      "@bp2": {
        width: "330px !important",
        marginLeft: "30px",
      },
      "@bp900": {
        marginLeft: 0,
      },

      "& .buttons-container": {
        display: "flex",
        marginTop: "10px",
        width: "100%",
        "& button": {
          transition: "all 0.2s ease-in-out",
        },
      },

      "@mobile": {
        width: "calc(100%) !important",
        textAlign: "left !important",
        marginLeft: 0,
        "& button": {
          fontSize: "14px !important",
        },

        "& .subtitle": {
          fontSize: "28px",
        },
      },
    },
    "& .title": {
      textAlign: "left !important",
    },
    "& .subtitle": {
      // fontFamily: "WorkSans",
      // fontSize: "15px",
      lineHeight: "1.2",
      textAlign: "left !important",
      marginBottom: "6px",
      // marginTop: "18px",
      "& .new": {
        color: "#E3AEFF",
        fontWeight: "500",
      },
    },
    "& .sub-subtitle": {
      fontSize: "14px",
      textAlign: "left !important",
      lineHeight: "1.4",
      marginBottom: "18px",
      // marginTop: "30px",
      "& .read-more": {
        textDecoration: "underline",
        color: "#F3C092",
        transition: "opacity 0.2s",
        "&:hover": {
          opacity: 0.8,
        },
      },
    },
    "& .bookings-header-img-container": {
      width: "100%",
      paddingRight: "50px",
      paddingLeft: "40px",
      display: "flex",
      justifyContent: "flex-end",
      "@bp2": {
        paddingRight: "10px",
      },
      "@bp900": {
        paddingRight: 0,
        paddingLeft: 0,
        justifyContent: "center",
      },
      flexDirection: "column",
    },

    "& .bookings-header-img": {
      maxWidth: "min(450px, 100%)",
      // aspectRatio: "1/1",
      border: "1px solid #000",
      flexShrink: 0,

      alignSelf: "flex-start",
      borderRadius: "9px",
      "@bp900": {
        maxWidth: "430px",
        alignSelf: "center",
        marginTop: "30px",
        marginRight: 0,
        marginLeft: 0,
      },
      "@mobile": {
        // width: "calc(100% - 16px)",
        maxWidth: "100%",
        marginLeft: 0,
        marginTop: "25px",
      },
    },

    "&.decks-header": {
      backgroundColor: "#E6E6E6",
      border: "1px solid black",
      "& .title, & .sub-subtitle": {
        color: "black",
      },
      "& .buttons-container button": {
        backgroundColor: "#EDC29A",
        color: "black",
        border: "1px solid black !important",
        maxWidth: "unset !important",

        "&:hover": {
          opacity: 0.8,
        },
      },
    },
  },
  "& .orange-header.text-container": {
    backgroundColor: "#EDC29A",
    border: "1px solid black",
    boxShadow: "#000 0px 2px",
    "& .title": {
      fontSize: "52px",
    },
    "& .subtitle": {
      marginTop: "4px",
    },
    "& .sub-subtitle": {
      fontSize: "15px",
      fontWeight: "400",
    },
  },

  "& .more-pros-header.text-container": {
    backgroundColor: "black",
    color: "white",
    width: "calc(90% - 24px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "33px",
    padding: "65px 40px 20px 40px",
    marginBottom: "40px",
    "& .subtitle": {
      maxWidth: "450px",
      fontSize: "14px",
      fontFamily: "WorkSans",
      fontWeight: "400",
      lineHeight: "1.4",
      marginTop: "16px",
      // marginTop: "0px",
      "@mobile": {
        fontSize: "14px",
      },
    },
    "@bp3": {
      width: "100%",
    },
  },

  "& .title": {
    fontFamily: "GorgeousBaby",
    fontWeight: "200",
    fontSize: "40px",
    marginBottom: "8px",
    "@mobile": {
      fontSize: "44px",
    },
  },
  "& .subtitle": {
    fontSize: "24px",
    fontWeight: "400",
    marginTop: "10px",
    fontFamily: "KeplerLightCondensed",
    "@mobile": {
      fontSize: "26px",
      lineHeight: "25px",
    },
  },
  "& .sub-subtitle": {
    fontSize: "12px",
    fontWeight: "normal",
    // textTransform: "uppercase",
    marginTop: "14px",
  },
  "& .buttons-container": {
    marginTop: "22px",
  },
  "& .text": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginLeft: "12px",
    flexDirection: "column",
    marginBottom: "50px",
    "@mobile": {
      marginBottom: "40px",
    },
    "@notmobile": {
      "& br": {
        display: "none",
      },
    },
  },

  "& .availability-toggle": {
    display: "flex",
    justifyContent: "flex-end",
    width: "calc(90% - 32px)",
    marginBottom: "12px",
    "& .toggle-section": {
      display: "flex",
      alignItems: "center",
      "& .toggle-label": {
        fontSize: "13px",
        fontWeight: "500",
        marginRight: "8px",
      },
    },
  },

  "& .no-available": {
    marginTop: "30px",
    marginBottom: "90px",
    fontSize: "13px",
    textAlign: "center",
    "& .l1": {
      // italic
      fontStyle: "italic",
      marginBottom: "4px",
    },
  },

  "& .cards": {
    display: "flex",
    width: "calc(90%)",
    flexDirection: "row",
    flexWrap: "wrap",
    // marginLeft: "-14px",
    marginLeft: 0,
    "& .card": {
      marginLeft: 0,
    },
    marginBottom: "40px",
    // justifyContent: "space-between",
  },
  "& .available-separator": {
    width: "calc(90% - 16px)",
    marginLeft: "8px",
    borderTop: "1px solid black",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    marginTop: "20px",

    "& .separator-text": {
      fontFamily: "KeplerLightCondensed",
      fontSize: "25px",
      marginTop: "-14px",
      backgroundColor: "$wash",
      width: "fit-content",
      padding: "0 12px",
    },
  },
  variants: {},
});

const StyledProCard = styled("div", {
  cursor: "none",
  marginLeft: "12px",
  marginRight: "12px",
  width: "calc(33.3333% - 24px)",
  "@bp2": {
    width: "calc(50% - 24px)",
  },
  "@bp3": {
    width: "calc(100%)",
    marginLeft: 0,
    marginRight: 0,
  },
  border: "0.5px solid rgba(0, 0, 0, 0.4)",
  borderRadius: "33px",
  boxShadow: "0px 4px 0px 0px #000000",
  transition: "all 0.1s ease-in-out",
  backgroundColor: "white",
  flexShrink: 0,
  alignSelf: "center",
  position: "relative",

  // "& .pro-pic": {
  //   width: "40%",
  //   flexShrink: 0,
  //   alignSelf: "center",
  //   borderRadius: "50%",
  // },
  "& .card-content": {
    marginLeft: "18px",
    height: "130px",
    overflow: "hidden",
    width: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    marginTop: "-20px",
  },
  "& .tags": {
    position: "absolute",
    bottom: "12px",
    right: "20px",
    display: "flex",
    justifyContent: "flex-end",

    width: "60%",
    "& .tag": {
      width: "54px",
      padding: "2px 4px",
      marginRight: "2px",
      marginLeft: "2px",
      borderRadius: "18px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontSize: "9px",
      textTransform: "uppercase",
      backgroundColor: "$wash",
      "& svg": {
        marginBottom: "4px",
      },
      "&.reader": {
        backgroundColor: "#F6DCFF",
      },
      "&.teacher": {
        backgroundColor: "#FAD7BE",
      },
      "&.author": {
        backgroundColor: "#F0E9E1",
      },
      "&.artist": {
        backgroundColor: "#FFEFB4",
      },
    },
  },
  "& .name": {
    fontSize: "14px",
    fontWeight: "500",
    height: "40px",
    lineHeight: "16px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "end",
    marginBottom: "6px",
  },
  "& .tagline": {
    textTransform: "lowercase",
    fontSize: "13px",
    fontWeight: "400",
    marginTop: "3px",
  },
  "& .bio": {
    height: 0,
    pointerEvents: "none",
    fontSize: "11px",
    opacity: 0,
  },
  "& .name, & .tagline, & .tags": {
    transition: "opacity 0.3s ease-in",
  },
  "&:hover": {
    "@notmobile": {
      // transform: "scale(1.02)",
      transform: "translateY(4px)",
      boxShadow: "0px 0px 0px 0px #000000",

      "& .card-content": {
        marginTop: 0,
      },
      "& .bio": {
        transition: "opacity 0.3s ease-in",
        opacity: 1,
        height: "114px",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxPack: "center",
        // WebkitLineClamp: 9,
        WebkitBoxOrient: "vertical",
      },
      "& .name, & .tagline, & .tags": {
        transition: "none",
        pointerEvents: "none",
        height: 0,
        lineHeight: 0,
        opacity: 0,
      },
    },
  },
  // height: "180px",
  display: "flex",
  alignItems: "center",
  minHeight: "200px",
  marginBottom: "30px",
  "@mobile": {
    marginBottom: "22px",
  },
  padding: "18px",
});

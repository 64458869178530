import { MotionConfig } from "framer-motion";
import { Room } from "./pages/room/Room";
import { Route, Switch, useLocation } from "wouter";
import { ActionProvider } from "./ui/room/Actions";
import { Spreaditor } from "./pages/spreaditor/Spreaditor";
import { AuthFormState, AuthPage } from "./pages/login/Auth";
import { AuthContext } from "./utils/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import RedirectPage, { RedirectPageWithKnownRef } from "./pages/404/Redirect";
import { TopBar } from "./ui/menus/TopBar";
import { PageNotFound } from "./pages/404/PageNotFound";
import { LoadingPage } from "./pages/loading/LoadingPage";
import { useContext, useEffect, useState } from "react";
import { RoomNotFound } from "./pages/404/RoomNotFound";
import { localState } from "~/state/state";
import { TOAST_TIME_MS } from "./utils/consts";
import { useSnapshot } from "valtio";
import Home, { MOONLIGHT_PAGE } from "./pages/Home";
import { sendPageEvent } from "./utils/analytics";
import { PostLeavingSurvey } from "./ui/menus/Survey";
import ProfilePage from "./pages/profiles/Profile";
import { BillingPage, Success } from "./pages/marketplace/Billing";
import { Oauth2Callback } from "./pages/callback/Oauth2";
import { SessionView } from "./pages/marketplace/Session";
import { SessionAction } from "./pages/callback/SessionAction";
import { clearToast } from "./utils/handleToasts";
import { MetaTagHeaders } from "./ui/components/MetaTags";
import { SessionVideoPlayer } from "./ui/components/SessionVideoPlayer";

function App() {
  const { currentMessage, isConnecting, isOnMobile, isInRoom } =
    useSnapshot(localState);

  const { user, getUser, logout } = useContext(AuthContext);
  const [location] = useLocation();
  const [messageTimer, setMessageTimer] = useState<number | null>(null);

  // On app load, if user is logged in, refresh user
  useEffect(() => {
    if (!!user) {
      getUser();
    }
  }, []);

  // TODO: not necessary anymore, where isOnPlusPlan is in localStorage should be moved to user object reference
  useEffect(() => {
    if (user && user.username) {
      localStorage.setItem("sessionId", user.username);
    }
    (async () => {
      if (user) {
        const isOnPlusPlan = user.isPlus || user.isProfessional;
        localState.isOnPlusPlan = isOnPlusPlan;
      }
    })();
  }, [user]);

  useEffect(() => {
    if (isOnMobile) {
      localState.isGuidebookOpen = false;
    }
  }, [isOnMobile]);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // true for mobile device
      localState.isOnMobile = true;
    } else {
      localState.isOnMobile = false;
    }
  }, [navigator.userAgent]);

  useEffect(() => {
    // default to not in room
    localState.isInRoom = false;
    if (!location.includes("/room/")) {
      localState.isConnecting = false;
    }
    sendPageEvent(location);
  }, [location]);

  // manage toasts
  useEffect(() => {
    if (currentMessage.isPerpetual) return;
    if (currentMessage.msg) {
      if (messageTimer) window.clearTimeout(messageTimer);
      const tmr = window.setTimeout(() => {
        clearToast();
      }, TOAST_TIME_MS);
      setMessageTimer(tmr);
    }
  }, [currentMessage]);

  return (
    <div
      style={{
        width: "100vw",
        position: "relative",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      {<MetaTagHeaders />}
      <MotionConfig
        transition={{
          type: "spring",
          stiffness: 500,
          damping: 45,
        }}
      >
        <ActionProvider>
          <ToastContainer />

          <TopBar user={user} signout={logout} />

          <>
            {isConnecting && <LoadingPage noText />}
            {user ? (
              <Switch>
                <Route path="/home">
                  <Home user={user} page={MOONLIGHT_PAGE.HOME} />;
                </Route>
                <Route path="/create-room">
                  <Home user={user} page={MOONLIGHT_PAGE.CREATE_ROOM} />;
                </Route>
                <Route path="/signin">
                  <AuthPage state={AuthFormState.LOGIN} />
                </Route>
                <Route path="/signup">
                  <AuthPage state={AuthFormState.REGISTER} />
                </Route>
                <Route path="/signout">
                  <AuthPage state={AuthFormState.SIGNOUT} />
                </Route>
                <Route path="/editor">
                  <Spreaditor />
                </Route>
                <Route path="/room/:roomId">
                  {(params) => <Room id={params.roomId} user={user} />}
                </Route>
                <Route path="/profile/:username">
                  {(params) => <ProfilePage username={params.username} />}
                </Route>
                <Route path="/session/:sessionId">
                  {(params) => <SessionView id={params.sessionId} />}
                </Route>

                <Route path="/">
                  <Home page={MOONLIGHT_PAGE.HOME} user={user} />
                </Route>
                <Route path="/leave">
                  <PostLeavingSurvey
                    email={user.email}
                    username={user.username}
                    dest={window.location.host + "/"}
                  />
                </Route>
                <Route path="/decks/:deckPageId">
                  {(params) => (
                    <Home
                      user={user}
                      page={MOONLIGHT_PAGE.DECKS}
                      deckPageId={params.deckPageId}
                    />
                  )}
                </Route>
                <Route path="/decks">
                  <Home user={user} page={MOONLIGHT_PAGE.DECKS} />
                </Route>

                <Route path="/pros">
                  <RedirectPageWithKnownRef href={"/book"} />
                </Route>
                <Route path="/book">
                  <Home user={user} page={MOONLIGHT_PAGE.BOOK} />;
                </Route>
                <Route path="/availability">
                  <Home user={user} page={MOONLIGHT_PAGE.AVAILABILITY} />;
                </Route>
                <Route path="/sessions">
                  <Home user={user} page={MOONLIGHT_PAGE.UPCOMING} />;
                </Route>

                <Route path="/plans">
                  <BillingPage />
                </Route>

                <Route path="/success">
                  <Success />
                </Route>
                <Route path="/session-action">
                  <SessionAction />
                </Route>
                <Route path="/session/video/:sessionId">
                  {(params) => (
                    <SessionVideoPlayer
                      id={params.sessionId ? params.sessionId : ""}
                    />
                  )}
                </Route>
                <Route path="/session/:sessionId">
                  {(params) => <SessionView id={params.sessionId} />}
                </Route>
                <Route path="/callback/oauth2">
                  <Oauth2Callback user={user} />
                </Route>
                <Route path="/404">
                  <PageNotFound />
                </Route>
                <Route path="/room404">
                  <RoomNotFound />
                </Route>

                <Route>
                  <RedirectPage />
                </Route>
              </Switch>
            ) : (
              <Switch>
                <Route path="/">
                  <Home user={user} page={MOONLIGHT_PAGE.HOME} />;
                </Route>
                <Route path="/create-room">
                  <Home user={user} page={MOONLIGHT_PAGE.CREATE_ROOM} />;
                </Route>
                <Route path="/signin">
                  <AuthPage state={AuthFormState.LOGIN} />
                </Route>
                <Route path="/signup">
                  <AuthPage state={AuthFormState.REGISTER} />
                </Route>
                <Route path="/forgotPassword">
                  <AuthPage state={AuthFormState.FORGOTPASSWORD} />
                </Route>
                <Route path="/resetPassword">
                  <AuthPage state={AuthFormState.RESETPASSWORD} />
                </Route>
                <Route path="/session-action">
                  <SessionAction />
                </Route>
                <Route path="/room/:roomId">
                  {(params) => <Room id={params.roomId} />}
                </Route>
                <Route path="/profile/:username">
                  {(params) => <ProfilePage username={params.username} />}
                </Route>
                <Route path="/session/video/:sessionId">
                  {(params) => (
                    <SessionVideoPlayer
                      id={params.sessionId ? params.sessionId : ""}
                    />
                  )}
                </Route>
                <Route path="/session/:sessionId">
                  {(params) => <SessionView id={params.sessionId} />}
                </Route>
                <Route path="/plans">
                  <BillingPage />
                </Route>
                <Route path="/decks/:deckPageId">
                  {(params) => (
                    <Home
                      user={user}
                      page={MOONLIGHT_PAGE.DECKS}
                      deckPageId={params.deckPageId}
                    />
                  )}
                </Route>
                <Route path="/decks">
                  <Home user={user} page={MOONLIGHT_PAGE.DECKS} />;
                </Route>

                <Route path="/pros">
                  <RedirectPageWithKnownRef href={"/book"} />
                </Route>
                <Route path="/sessions">
                  <RedirectPageWithKnownRef href={"/signin"} />
                </Route>
                <Route path="/book">
                  <Home user={user} page={MOONLIGHT_PAGE.BOOK} />;
                </Route>
                <Route path="/success">
                  <Success />
                </Route>
                <Route path="/404">
                  <PageNotFound />
                </Route>
                <Route>
                  <RedirectPage />
                </Route>
              </Switch>
            )}
          </>
        </ActionProvider>
      </MotionConfig>
    </div>
  );
}

export default App;

import { useSnapshot } from "valtio";
import { localState } from "~/state/state";
import { keyframes, styled } from "../style/stitches.config";
import { useContext, useEffect, useRef, useState } from "react";
import { getRecordingUrl, getSessionById } from "~/api/sessionApi";
import { generatePresignedAccessUrl } from "~/api/roomApi";
import { SimpleLoadingIndicator } from "~/pages/loading/LoadingPage";
import { SocialBottomBar, StyledBottomNavBar } from "../home/HomeNavBar";
import { Session } from "~/types/sessions/types";
import { getUser, UserProfile } from "~/api/userApi";
import { imageUrl } from "~/utils/imageurl";
import { SpinStar } from "./SpinStar";
import { yyyyMMddToDate } from "~/utils/datetime";
import { QuestionHover } from "./Tooltip";
import { CopyButton } from "./CopyButton";
import { Button } from "./Button";
import { HelpButtonSmall } from "./HelpButton";
import { AuthContext } from "~/utils/useAuth";
import { sendToast } from "~/utils/handleToasts";
import { Message } from "./Message";
import { StyledVideoPlayButton } from "~/pages/marketplace/ProDirectory";
import { trackEvent } from "~/api/analyticsApi";
import {
  BookingRegistrationSurvey,
  Survey,
  VideoSessionSurvey,
} from "../menus/Survey";

export const VideoPlayerButtons = ({
  url,
  downloadName,
  sessionId,
  hostName,
}: {
  url: string;
  downloadName: string;
  sessionId: string;
  hostName: string;
}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <StyledVideoPlayerButtons>
      <Button
        className="wide"
        style={{ filter: "invert(1)" }}
        onClick={() => {
          trackEvent("/session/video/book_again", {
            session_id: sessionId,
          });
          window.open("/profile/" + hostName, "_blank");
        }}
      >
        Book again
      </Button>

      <Button
        onClick={(e) => {
          e.preventDefault();
          if (!linkCopied)
            navigator.clipboard.writeText(window.location.href).then(() => {
              setLinkCopied(true);
              window.setTimeout(() => {
                setLinkCopied(false);
              }, 4000);
            });
        }}
      >
        {linkCopied ? (
          "Copied!"
        ) : (
          <>
            <img className="icon" src="/images/room/copy-link-icon.svg"></img>
            {/* {`Share `} */}
            <span style={{ marginLeft: "4px" }} className="extra"></span>
          </>
        )}
      </Button>
      <Button
        style={{ position: "relative" }}
        onClick={(e) => {
          (async () => {
            try {
              setIsDownloading(true);
              trackEvent("/session/video/downloaded_video", {
                session_id: sessionId,
              });
              // download video URL
              const response = await fetch(url);
              const blob = await response.blob();
              const blobURL = window.URL.createObjectURL(blob);

              const link = document.createElement("a");
              link.href = blobURL;
              link.download = downloadName; // Set the default filename here
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              // Clean up the blob URL after the download
              window.URL.revokeObjectURL(blobURL);
            } catch (error) {
              sendToast(true, 1179);
            } finally {
              setIsDownloading(false);
            }
          })();
        }}
      >
        {isDownloading ? (
          <>
            Saving
            <span className="saving-dots">
              <span className="dot dot-1">.</span>
              <span className="dot dot-2">.</span>
              <span className="dot dot-3">.</span>
            </span>
          </>
        ) : (
          <>
            <img className="icon" src="/images/save-icon-thick.svg"></img>
            {/* {`Save `} */}
            <span style={{ marginLeft: "4px" }} className="extra"></span>
          </>
        )}
      </Button>
    </StyledVideoPlayerButtons>
  );
};

const dot1 = keyframes({
  "0%": {
    opacity: 0,
  },
  "24%": {
    opacity: 0,
  },
  "25%": {
    opacity: 1,
  },
});
const dot2 = keyframes({
  "0%": {
    opacity: 0,
  },
  "49%": {
    opacity: 0,
  },
  "50%": {
    opacity: 1,
  },
});
const dot3 = keyframes({
  "0%": {
    opacity: 0,
  },
  "74%": {
    opacity: 0,
  },
  "75%": {
    opacity: 1,
  },
});

const StyledVideoPlayerButtons = styled("div", {
  "& button": {
    backgroundColor: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingRight: 0,
    paddingLeft: 0,
    fontSize: "12px",
    marginRight: "8px",
    borderColor: "#fff",
    width: "62px",
    height: "44px",
    "&.wide": {
      width: "100px",
    },
    "& img": {
      width: "20px",
      height: "20px",
      filter: "invert(1)",
    },
    "&:hover": {
      backgroundColor: "$darkwash",
      opacity: 0.8,
    },
  },
  position: "absolute",
  top: "60px",
  right: "20px",
  display: "flex",
  flexDirection: "row-reverse",
  "& .saving-dots": {
    "& .dot": {
      "&.dot-1": {
        animation: `${dot1} 1.5s infinite`,
      },
      "&.dot-2": {
        animation: `${dot2} 1.5s infinite`,
      },
      "&.dot-3": {
        animation: `${dot3} 1.5s infinite`,
      },
    },
  },
});

export const SessionVideoPlayer = ({ id }: { id: string }) => {
  const [sessionData, setSessionData] = useState<Session | null>(null);
  const [clientData, setClientData] = useState<UserProfile | null>(null);
  const [hostData, setHostData] = useState<UserProfile | null>(null);
  const [videoURL, setVideoURL] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const { user } = useContext(AuthContext);
  const { isOnMobile } = useSnapshot(localState);

  useEffect(() => {
    localState.inDarkMode = true;
    (async () => {
      if (!id) return;
      console.log(id);
      const session = await getSessionById(id, "");
      setSessionData(session);
      const host = await getUser(session.ownerId);
      setHostData(host);
      const clientIds = session.invitees.filter((i) => i !== session.ownerId);
      if (clientIds.length > 0) {
        const client = await getUser(clientIds[0]);
        setClientData(client);
      }

      if (session.recording) {
        const url = await getRecordingUrl(session.id);
        setVideoURL(url);
        setIsLoading(false);
      }
    })();
    return () => {
      localState.inDarkMode = false;
    };
  }, []);

  return (
    <StyledSessionVideoPlayerBackground>
      <div>
        <StyledSessionVideoPlayer>
          <Message />
          {isLoading && !videoURL ? (
            <SimpleLoadingIndicator />
          ) : (
            <>
              <div id="session-info">
                <div id="image-sidebar">
                  <img
                    src={imageUrl(`/profile-pics/${hostData?.profilePic}`, 200)}
                  ></img>
                </div>
                <div id="text-sidebar">
                  <div className="title">
                    {`Tarot Session for `}
                    <a
                      href={`/profile/${clientData?.username}`}
                      target="_blank"
                    >
                      {clientData?.name}
                    </a>
                    {` by `}
                    <a href={`/profile/${hostData?.username}`} target="_blank">
                      {hostData?.name}
                    </a>
                  </div>
                  {sessionData?.sessionTime && (
                    <div className="subtitle">
                      {`Requested on ${
                        sessionData?.sessionTime.month
                      } ${yyyyMMddToDate(
                        sessionData?.sessionTime.yyyyMMdd
                      ).getDate()}, ${yyyyMMddToDate(
                        sessionData?.sessionTime.yyyyMMdd
                      ).getFullYear()}`}{" "}
                      <QuestionHover
                        vertical={true}
                        whiteBorder={true}
                        text={sessionData.sessionUserContext[0]?.context}
                        menuStyle={{
                          fontStyle: "italic",
                          textAlign: "left",
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="video-container">
                {!isPlaying && (
                  <StyledVideoPlayButton
                    style={{
                      width: "32px",
                      zIndex: 999,
                      pointerEvents: "none",
                    }}
                    className="play-button"
                    src="/images/play-button.png"
                  />
                )}

                <video
                  id={"session-video"}
                  controls
                  onError={() => {
                    sendToast(true, 1178);
                  }}
                  onPlay={() => {
                    setIsPlaying(true);
                    trackEvent("/session/video/viewed_video", {
                      session_id: sessionData?.id,
                    });
                  }}
                  onPause={() => {
                    setIsPlaying(false);
                  }}
                >
                  <source src={videoURL ? videoURL : ""} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <VideoPlayerButtons
                hostName={hostData?.username ?? ""}
                sessionId={sessionData?.id ?? ""}
                url={videoURL ? videoURL : ""}
                downloadName={`Moonlight Tarot Reading for ${
                  clientData?.username
                } by ${hostData?.username} - ${new Date().toLocaleString(
                  "default",
                  { month: "long" }
                )} ${new Date().getDate()}, ${new Date().getFullYear()}`}
              />
            </>
          )}
        </StyledSessionVideoPlayer>
      </div>
      {!isLoading && (
        <div className="video-survey-container">
          <VideoSessionSurvey
            hostEmail={hostData?.email}
            requesterName={clientData?.name}
          />
        </div>
      )}

      {isOnMobile ? (
        <>
          <SocialBottomBar
            style={{
              filter: "invert(1)",
              borderTop: "1px solid black",
              height: "40px",
            }}
          />
        </>
      ) : (
        <>
          <div style={{ position: "fixed", bottom: "60px", right: "20px" }}>
            <HelpButtonSmall />
          </div>
          <StyledBottomNavBar Theme={"dark"}>
            <SocialBottomBar />
          </StyledBottomNavBar>
        </>
      )}
    </StyledSessionVideoPlayerBackground>
  );
};

const StyledSessionVideoPlayerBackground = styled("div", {
  width: "100%",
  height: "100%",
  backgroundColor: "$darkwash",
  "& .video-survey-container": {
    maxWidth: "700px",
    width: "60%",
    margin: "0 auto",
    "@mobile": {
      width: "90%",
    },
  },
});

const StyledSessionVideoPlayer = styled("div", {
  left: 0,
  top: 0,
  zIndex: 999,
  color: "white",
  fontSize: 9999,
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  margin: 0,
  "@mobile": {
    paddingTop: "50px",
  },

  "& .video-container": {
    display: "flex",
    marginTop: "140px",
    maxWidth: "700px",
    position: "relative",
    width: "60%",
    overflow: "hidden",
    borderRadius: "16px",
    border: "1px solid  #999999",
    maxHeight: "400px",
    "@mobile": {
      width: "90%",
    },
  },
  "& video": {
    width: "100%",
    flex: 1,
  },
  "& .bottom-button-container": {
    display: "flex",
    position: "fixed",
    bottom: "80px",
    "& button": {
      width: "160px",
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: "6px",
      marginRight: "6px",
    },
    "@mobile": {
      flexDirection: "column",
      "& button": {
        marginBottom: "8px",
      },
    },
  },
  "& #session-info": {
    position: "absolute",
    top: "60px",
    left: "20px",
    display: "flex",
    flexDirection: "row",
    "@mobile": {
      top: "120px",
    },

    "& #image-sidebar": {
      display: "flex",
      flexDirection: "column",
      "& img": {
        width: "50px",
        height: "50px",
        borderRadius: "50%",
        marginBottom: "18px",
      },
    },
    "& #text-sidebar": {
      display: "flex",
      flexDirection: "column",
      flexShrink: 0,
      color: "white",
      fontSize: "14px",
      marginTop: "8px",
      marginLeft: "8px",
      "& .title": {
        fontSize: "13px",
        "& a": {
          textDecoration: "underline",
          "&:hover": {
            opacity: 0.7,
          },
        },
      },
      "& .subtitle": {
        fontSize: "11px",
        fontWeight: "200",
        letterSpacing: "0.02em",
        display: "flex",
        marginTop: "6px",
      },
    },
  },
});
